import React, { useState } from "react";
import useIsMobile from "../hooks/useIsMobile";
import Section from "../components/Section";
import Carrousel from "../components/Carroussel";
import CardFidelity from "../components/CardFidelity";
import Modal from "../components/Modal";
import { useAppContext } from "../contexts/AppContext";
import ModalAvantages from "../components/ModalAvantages";
import Footer from "../components/Footer";
export default function BPartnerFidelite() {
  const isMobile = useIsMobile();
  const [formData, setFormData] = useState({
    type: "professionnel",
    offre: "prestige",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const { updateContext } = useAppContext();
  const openModal = () => {
    updateContext("modalOpen", true);
    updateContext("modalElement", <ModalAvantages />);
    updateContext("modalData", formData);
  };
  return (
    <div
      className={`flex flex-col font-poppins ${
        !isMobile ? "h-auto overflow-hidden" : ""
      }`}
    >
      <div>
        <div className="container-snap relative h-screen">
          <Modal />
          <img
            className="absolute  left-0 w-full h-[100%] object-cover z-0 object-right-top grayscale"
            src="./assets/images/loyalty-top-section.png"
          />

          <div className="relative z-10 flex px-[1.5rem] lg:px-[3rem] xx:px-[6rem] flex-col pt-[25%]  lg:pt-0 text-white h-screen justify-start lg:justify-center lg:items-center bg-[#0000004f] space-y-[2rem] lg:space-y-5">
            <p className="font-bold tracking-wider text-center md:text-left text-main-red uppercase lg:text-[1.25rem] xx:text-[1.5rem]">
              B PARTNER FIDÉLITÉ
            </p>
            <p className="font-semibold text-[1.75rem] text-center md:text-left lg:text-[2.5rem] xx:text-[2.5rem]">
            Dépensez, cumulez, profitez.
            </p>
            <p className="text-center ">Chaque transaction devient une opportunité de récompense.</p>
          </div>
        </div>
        <Section
          id="avantages"
          title="DES AVANTAGES AU QUOTIDIEN"
          subTitle="CHAQUE TRANSACTION EST RÉCOMPENSÉE"
          text="Prestige ou Premium, chaque utilisation de votre carte vous rapproche d’avantages exclusifs :"
          bulletPoints={[
            <p>
              <span className="font-bold">Cumulez des points</span> à chaque paiement et transformez-les en réductions, services ou privilèges sur-mesure.
            </p>,
            <p>
              <span className="font-bold">Accédez à des expériences uniques :</span> offres de prestige, services luxueux et événements adaptés à votre style de vie.
            </p>,
            <p>
              <span className="font-bold">Profitez de récompenses personnalisées</span>{" "}
              pour les voyageurs, les fins gourmets et les passionnés de shopping.
            </p>,
          ]}
          imgName="img1-bpf.png"
          altImg="girl walking by"
          align="left"
          button={false}
        />

        <div className="flex flex-col font-poppins px-[1.5rem] lg:justify-center mt-14 lg:mt-20">
          <p className="uppercase text-main-red tracking-wider font-semibold lg:tracking-widest xx:text-[1.25rem] text-left md:text-center">
            DES SERVICES D’EXCEPTION pour votre entreprise
          </p>
          <p className="font-bold text-[1.25rem] mt-2 lg:text-[1.875rem] uppercase text-left md:text-center">
            Inclus dans l'offre prestige
          </p>
          <div className="xx:w-fit xx:mx-auto">
            <Carrousel id="fidelite">
              <CardFidelity
                img="./assets/images/img1-pro-1.png"
                alt={"reception"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    <span className="text-main-red">Activez</span> votre compte
                    B Partner
                  </p>
                }
                text={
                  "Activer votre compte vous rapporte directement des points."
                }
                goTo={"/activate"}
                from="/bPartnerFidelite#fidelite"
              />
              <CardFidelity
                img="./assets/images/img2-pro-2.png"
                alt={"carte"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    <span className="text-main-red">Payez</span> avec votre
                    carte
                  </p>
                }
                text={
                  "Utilisez votre carte B Partner pour régler vos achats partout dans le monde."
                }
                goTo={"/achatCarte"}
                from="/bPartnerFidelite#fidelite"
              />
              <CardFidelity
                img="./assets/images/friends.png"
                alt={"friends talking"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    <span className="text-main-red">Parrainez </span> vos amis
                  </p>
                }
                text={
                  "Invitez vos amis à ouvrir un compte chez B Partner et à l’activer."
                }
                goTo={"/parrainage"}
                from="/bPartnerFidelite#fidelite"
              />
              <CardFidelity
                img="./assets/images/img4-pro-4.png"
                alt={"man on laptop"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    Réalisez des{" "}
                    <span className="text-main-red">transferts en devise </span>
                  </p>
                }
                text={
                  "Vos paiements en euro et en devise vous permettent de cumuler des points. "
                }
                goTo={"/deviseFidelite"}
                from="/bPartnerFidelite#fidelite"
              />
              <CardFidelity
                img="./assets/images/img5-pro-5.png"
                alt={"girl looking at the camera"}
                title={
                  <p className="font-bold text-sm lg:text-base text-left">
                    Réalisez des{" "}
                    <span className="text-main-red">virements sortants</span>
                  </p>
                }
                text={
                  "Vos virements en euro et en devise vous permettent aussi de cumuler des points.*"
                }
                subText="*réservé aux clients professionnels"
                goTo={"/virements"}
                from="/bPartnerFidelite#fidelite"
              />
            </Carrousel>
          </div>
        </div>
        <div className="flex flex-col font-poppins px-[1.5rem] lg:justify-center mt-14 lg:mt-20">
          <p className="text-main-red uppercase tracking-wider font-semibold text-left md:text-center">
            multipliez vos points{" "}
          </p>
          <p className="mt-2 text-[1.5rem] font-bold lg:text-[2.5rem] lg:w-2/3 lg:mx-auto text-left md:text-center">
          Cumulez des points de Fidélité et des points de Statut.
          </p>
          <div className="mt-4 space-y-8 lg:space-y-0 lg:flex lg:flex-row lg:justify-center lg:space-x-12 lg:mt-12">
            <div className="lg:w-[30rem]">
              <img
                alt="trophy icon"
                src="./assets/images/square1.png"
                className="w-[4.125rem] h-auto"
              />
              <p className="text-left font-bold text-[1.25rem] mt-4">
                Points de Fidélité
              </p>
              <p className="text-left">
              Accumulez des points dès le premier euro dépensé, en France comme à l’international, ou en parrainant vos proches. 
              </p>
              {/* <ul className="mt-4 text-left text-dark-grey list-disc ml-4">
                <li>
                  une fois votre <strong>compte actif</strong>
                </li>
                <li>
                  dès votre <strong>premier euro d’achat</strong> en France ou à
                  l’étranger
                </li>
                <li>
                  dès que vous <strong>parrainez</strong> un proche ou un ami{" "}
                </li>
                <li>
                  lors de vos visites chez nos <strong>partenaires</strong>
                </li>
              </ul> */}
              <p className="text-main-red text-left mt-4">Vous gagnez également des points lors de vos achats chez nos partenaires. Et parce que vos efforts méritent d’être récompensés, vos points sont valables à vie.</p>
            </div>
            <div className="lg:w-[30rem]">
              <img
                alt="gold badge icon"
                src="./assets/images/square2.png"
                className="w-[4.125rem] h-auto"
              />
              <p className="text-left font-bold text-[1.25rem] mt-4">
                Points de Statut
              </p>
              <p className="text-left">
              Montez en niveau et multipliez vos points de Fidélité. Avec nos statuts <strong>Bronze, Silver, Gold et Platinium,</strong> chaque palier vous ouvre les portes d’avantages exclusifs et d’événements prestigieux organisés par nos soins ou avec nos partenaires. Plus haut est votre statut, plus grandes sont vos récompenses.
              </p>
              {/* <ul className="mt-4 text-left text-dark-grey list-disc ml-4">
                <li>
                  4 niveaux <strong>Bronze, Silver, Gold, Platinium</strong>
                </li>
                <li>
                  Plus vous montez de niveau, plus vous{" "}
                  <strong>multipliez</strong> vos points de Fidélité
                </li>
                <li>
                  Selon votre Statut, vous aurez accès à des{" "}
                  <strong>avantages et des événements </strong>exclusifs
                  présentés par B Partner et ses partenaires
                </li>
              </ul> */}
            </div>
          </div>
        </div>
        <div className="flex flex-col font-poppins px-[1.5rem] lg:flex-row md:px-[2rem] lg:items-center lg:justify-between xx:justify-around mt-14 lg:mt-20 lg:px-[3rem]">
          <div className="flex flex-col justify-start lg:order-2 lg:w-[30rem] xx:w-[40rem] lg:pr-12 ml-0 lg:ml-5">
            <p className="text-main-red uppercase tracking-wider font-semibold text-left">
              CALCULEZ VOS POINTS
            </p>
            <p className="mt-4 text-[1.5rem] font-bold uppercase lg:text-[2.5rem] text-left lg:mx-auto lg:w-fit">
              DÉCOUVREZ VOS AVANTAGES
            </p>
            <div className="flex flex-col space-y-8 mt-4 lg:flex-row lg:space-y-0 lg:justify-between text-dark-grey xx:justify-start xx:space-x-28">
              <div className="text-left space-y-4">
                <strong>Vous êtes :</strong>
                <div>
                  <input
                    type="radio"
                    id="particulier"
                    name="type"
                    value="particulier"
                    className="mr-2"
                    checked={formData.type === "particulier"}
                    onChange={handleChange}
                  />
                  <label htmlFor="particulier">Un particulier</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="professionnel"
                    name="type"
                    value="professionnel"
                    className="mr-2"
                    checked={formData.type === "professionnel"}
                    onChange={handleChange}
                  />
                  <label htmlFor="professionnel">Un professionnel</label>
                </div>
              </div>

              <div className="text-left space-y-4">
                <strong>Sélectionnez votre offre :</strong>
                <div>
                  <input
                    type="radio"
                    id="premium"
                    name="offre"
                    value="premium"
                    className="mr-2"
                    checked={formData.offre === "premium"}
                    onChange={handleChange}
                  />
                  <label htmlFor="premium">Premium</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="prestige"
                    name="offre"
                    value="prestige"
                    className="mr-2"
                    checked={formData.offre === "prestige"}
                    onChange={handleChange}
                  />
                  <label htmlFor="prestige">Prestige</label>
                </div>
              </div>
            </div>
            <button
              className="h-[3rem] border-main-red text-main-red rounded w-[12rem] font-semibold border-2 mt-8"
              onClick={openModal}
            >
              Voir mes avantages
            </button>
          </div>
          <img
            alt="woman looking for her b partner account"
            src="./assets/images/newimg2-bpf.png"
            className="mt-8 lg:w-[41.25rem] lg:h-[29rem] xx:w-[50rem] xx:h-[34rem] lg:order-1 lg:mt-0"
          />
        </div>
        <div className="flex flex-col font-poppins px-[1.5rem] mt-14 lg:mt-20 lg:justify-center mb-12">
          <p className="text-left text-main-red tracking-wider uppercase font-semibold lg:w-fit lg:mx-auto">
            Dépensez vos points
          </p>
          <p className="text-left font-bold text-[1.5rem] mt-2 lg:text-[2.5rem] lg:w-fit lg:mx-auto">
          Profitez pleinement de vos récompenses
          </p>
          <p className="text-center mt-8">Vos points de Fidélité vous permettent de profiter d’avantages concrets, directement depuis votre compte :</p>
          <div className="lg:flex lg:flex-row lg:w-4/5 lg:mx-auto lg:justify-around lg:mt-8 xx:w-3/5">
            <div className="w-full h-[16.375rem] lg:w-[21rem] shadow-custom-red mt-8 flex flex-col justify-center items-center px-2 xx:w-[27rem] xx:h-[20rem] xx:px-6">
              <img
                alt="pay fees icon"
                src="./assets/images/square1.png"
                className="w-[5rem] h-auto"
              />
              <p className="text-[1.125rem] font-semibold mt-4">
                Payez vos frais et abonnements B Partner
              </p>
              <p className="text-[0.875rem]">
              Utilisez vos points pour régler votre abonnement mensuel en toute simplicité.
              </p>
            </div>
            <div className="w-full h-[16.375rem] lg:w-[21rem] shadow-custom-red mt-8 flex flex-col justify-center items-center px-2 xx:w-[27rem] xx:h-[20rem]  xx:px-6">
              <img
                alt="pay fees icon"
                src="./assets/images/square2.png"
                className="w-[5rem] h-[4rem]"
              />
              <p className="text-[1.125rem] font-semibold mt-4">
                Créditez votre compte B Partner
              </p>
              <p className="text-[0.875rem]">
              Convertissez vos points en euros et créditez instantanément votre compte courant.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
