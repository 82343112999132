import React from 'react';

export default function ProgressBar() {
  return (
    <div id='longProgressBar' className='border w-full lg:w-4/5 lg:mx-auto h-[8rem] mt-4 shadow-custom-red rounded flex flex-col justify-center items-center p-4 bg-white'>
      
      {/* Labels for each level above the bar */}
      <div className='flex flex-row w-full justify-around lg:justify-between lg:px-10 mb-2'>
        <div className='flex flex-col items-center'>
          <p className='text-[0.875rem] text-[#625D68]'>Bronze</p>
        </div>
        <div className='flex flex-col items-center'>
          <p className='text-[0.875rem] text-[#625D68]'>Silver</p>
        </div>
        <div className='flex flex-col items-center'>
          <p className='text-[0.875rem] text-[#625D68]'>Gold</p>
        </div>
        <div className='flex flex-col items-center'>
          <p className='text-[0.875rem] text-[#625D68]'>Platinium</p>
        </div>
      </div>
      
      <div id='progress-ctn' className='w-[80%] lg:w-[90%] mx-auto h-[0.7rem] bg-[#f3f2f2] relative overflow-visible rounded-full'>
        
        {/* Gradient progress bar */}
        <div className='absolute h-full rounded-full' style={{
          width: '100%',
          background: 'linear-gradient(to right, #ED8989, #980023)',
          borderRadius: '1rem'
        }}></div>

        {/* Dots */}
        <div className='w-4 h-4 rounded-full absolute left-0 top-1/2 transform -translate-y-1/2' style={{ backgroundColor: '#7D021F' }}></div>
        <div className='w-4 h-4 rounded-full absolute left-[33%] top-1/2 transform -translate-y-1/2' style={{ backgroundColor: '#7D021F' }}></div>
        <div className='w-4 h-4 rounded-full absolute left-[66%] top-1/2 transform -translate-y-1/2' style={{ backgroundColor: '#7D021F' }}></div>
        <div className='w-4 h-4 rounded-full absolute left-[96%] lg:left-[99%] top-1/2 transform -translate-y-1/2' style={{ backgroundColor: '#7D021F' }}></div>
      </div>
      
      {/* Points and Multipliers below the bar */}
      <div className='flex flex-row w-full justify-around mt-2  lg:justify-between lg:px-10 '>
        <div className='flex flex-col items-center '>
          <p className='text-[0.75rem] text-[#98949E]'>0 pts</p>
          <p className='text-[0.75rem] font-bold'>x100pts</p>
        </div>
        <div className='flex flex-col items-center '>
          <p className='text-[0.75rem] text-[#98949E]'>50 000 px</p>
          <p className='text-[0.75rem] font-bold'>x120pts</p>
        </div>
        <div className='flex flex-col items-center '>
          <p className='text-[0.75rem] text-[#98949E]'>150 000 px</p>
          <p className='text-[0.75rem] font-bold'>x150pts</p>
        </div>
        <div className='flex flex-col items-center '>
          <p className='text-[0.75rem] text-[#98949E]'>350 000 px</p>
          <p className='text-[0.75rem] font-bold'>x200pts</p>
        </div>
      </div>   
    </div>
  );
}
